<script setup>

import { provide, inject, onMounted, computed, getCurrentInstance } from 'vue'
import { Component as Vuedal } from 'vuedals'
import PopupLayer from '@/components/ui/PopupLayer.vue'
import UploadModal from '@/components/UploadModal.vue'
import PurchaseModal from '@/components/PurchaseModal.vue'
import PurchaseWaitModal from '@/components/PurchaseWaitModal.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'
import { formatFileSize } from '@/utils/format'

const $emit = defineEmits(['vuedals:new'])
const TEXT = inject('TEXT')
const statsStore = useStatsStore()
const globals = useGlobalStore()
const instance = getCurrentInstance()
const route = computed(() => instance?.proxy.$route)

const openInNewTab = function(url, downloadAs = '')
{
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  // link.rel = 'noopener noreferrer'
  if (downloadAs) link.download = downloadAs
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const openModal = function(options)
{
  $emit('vuedals:new', options)
}

const closeModal = function()
{
  $emit('vuedals:close')
}

const slowNonPremiumUser = async function()
{
  if (!statsStore.premiumActive)
  {
    const activate = await showPurchaseWaitModal()
    if (activate)
    {
      showPurchaseModal({ title: 'Prémiové služby' })
      return true
    }
  }
  return false
}

const blockNonPremiumUser = async function(text = 'Pre prístup k tejto funkcii je potrebné mať aktivované prémiové služby', title = '')
{
  if (!statsStore.premiumActive)
  {
    showPurchaseModal({ title: title, text:  text })
    return true
  }
  return false
}

const showPurchaseModal = function(params)
{
  const opts = {
    name: 'purchase-modal',
    closeOnBackdrop: false,
    dismissable: true,
    title: params?.title || '',
    component: PurchaseModal,
    props: { text: params?.text || '', viewMode: params?.mode || 'list' },
  }
  if (params?.onDismiss) opts.onDismiss = params.onDismiss
  if (params?.onClose) opts.onClose = params.onClose
  $emit('vuedals:new', opts)
}

const showPurchaseWaitModal = function()
{
  return new Promise(function(resolve, _reject)
  {
    const opts = {
      name: 'purchase-wait-modal',
      closeOnBackdrop: false,
      dismissable: false,
      title: '',
      component: PurchaseWaitModal
    }
    opts.onDismiss = () => { resolve() }
    opts.onClose = (data) => { resolve(data) }
    $emit('vuedals:new', opts)
  })
}

const UploadProgressModal = async function(params)
{
  const premium = statsStore.premiumActive
  if (!premium)
  { // without premium services
    const limit = statsStore?.premiumPackage.state?.current?.UPSZ?.size || 0
    if (limit > 0)
    {
      const files = params.files || []
      const largeFiles = files.filter(file => file.size > limit)
      if (largeFiles.length > 0)
      {
        const large = largeFiles[0].size
        showPurchaseModal({ text: TEXT('STR_PREMIUM_WARN_SIZE', { size: formatFileSize(large) }) })
        return
      }
    }
    const lformats = statsStore?.premiumPackage.state?.current?.UPTP?.types || []
    if (lformats.length > 0)
    { // allowed files
      const invalidFiles = params.files.filter(file => !lformats.includes(file.name.split('.').pop().toLowerCase()))
      if (invalidFiles.length > 0)
      {
        const ext = invalidFiles[0].name.split('.').pop().toLowerCase()
        showPurchaseModal({ text: TEXT('STR_PREMIUM_WARN_FORMAT', { format: ext.toUpperCase() }) })
        return
      }
    }
    const fformats = statsStore?.premiumPackage.state?.current?.UPTP?.ftypes || []
    if (fformats.length > 0)
    { // forbidden files
      const invalidFiles = params.files.filter(file => fformats.includes(file.name.split('.').pop().toLowerCase()))
      if (invalidFiles.length > 0)
      {
        const ext = invalidFiles[0].name.split('.').pop().toLowerCase()
        showPurchaseModal({ text: TEXT('STR_PREMIUM_WARN_FORMAT', { format: ext.toUpperCase() }) })
        return
      }
    }
    const dformats = statsStore?.premiumPackage.state?.current?.UPTP?.dtypes || []
    if (dformats.length > 0)
    { // dangerous files
      const invalidFiles = params.files.filter(file => dformats.includes(file.name.split('.').pop().toLowerCase()))
      if (invalidFiles.length > 0)
      {
        const ext = invalidFiles[0].name.split('.').pop().toLowerCase()
        showPurchaseModal({ text: TEXT('STR_PREMIUM_WARN_DANGER', { format: ext.toUpperCase() }) })
        return
      }
    }
    const purchase = await slowNonPremiumUser()
    if (purchase) return
  }
  $emit('vuedals:new',
    {
      component: UploadModal,
      props: { files: params.files, title: params.title, titles: params.titles, onDone: params.onDone, onError: params.onError, fields: params.fields, auth: params.auth, options: params.options },
      name: 'upload-modal',
      closeOnBackdrop: false,
      dismissable: false
    })
}

const handleDownloadFile = async function(url, file)
{
  if (file)
  {
    const premium = statsStore.premiumActive
    //const size = file?.size || 0
    //const limit = 2*1024*1024
    //if (size >= limit && premium === false)
    //{ // show modal with premium services options
    //  showPurchaseModal({ text: 'Pre sťahovanie väčších súborov zvážte zakúpenie prémiových služieb s výrazne vyššou rýchlosťou sťahovania súborov' })
    //}
    if (!globals.user || route.value.params.token) {
      openInNewTab(url)
      return
    }
    if (premium)
    {
      openInNewTab(url)
    }
    else
    {
      const activate = await showPurchaseWaitModal()
      if (activate) showPurchaseModal({ title: 'Prémiové služby' })
      else          openInNewTab(url)
    }
  }
}

onMounted(async () =>
{
  statsStore.resetPremiumPackageState()
  await statsStore.updatePremiumPackageState()
})

provide('openInNewTab', openInNewTab)
provide('openModal', openModal)
provide('closeModal', closeModal)
provide('slowNonPremiumUser', slowNonPremiumUser)
provide('blockNonPremiumUser', blockNonPremiumUser)
provide('handleDownloadFile', handleDownloadFile)
provide('showPurchaseModal', showPurchaseModal)
provide('showPurchaseWaitModal', showPurchaseWaitModal)
provide('UploadProgressModal', UploadProgressModal)

</script>

<template>
  <div id="app">
    <router-view />
    <vuedal></vuedal>
    <popup-layer class="light" />
  </div>
</template>
