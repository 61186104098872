<template>
  <div
    id="add-new-private-message"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        {{ subject ? `Správa - ${subject}` : 'Nová správa' }}
      </h4>
    </div>
    <div class="card-block">
      <form>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label
                for="subject"
                class="text-dark text-bold"
              >
                Predmet <small>*</small>
              </label>
              <input
                id="subject"
                v-model.lazy="subject"
                type="text"
                class="form-control"
                :class="{ error: form.subject.error, valid: form.subject.valid }"
                required="required"
                aria-required="true"
              >
              <label
                v-if="form.subject.error"
                class="error"
                for="subject"
              >
                Predmet správy musí byť vyplnený a môže obsahovať max. 250 znakov.
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="select-message-type"
                class="text-dark text-bold"
              >
                Typ správy <small>*</small>
              </label>
              <multiselect
                v-model="type"
                :options="messageTypeOptions"
                track-by="code"
                label="text"
                open-direction="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                placeholder="Vyberte typ správy"
              />
              <label
                v-if="form.type.error"
                class="error"
                for="select-message-type"
              >
                Musíte zvoliť typ správy.
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div :class="{'col-md-4': order.divided, 'col-md-8': !order.divided}">
            <div class="form-group">
              <label
                for="select-recipients"
                class="text-dark text-bold"
              >
                Príjemca <small>*</small>
              </label>
              <multiselect
                v-model="selectedRecipients"
                :options="recipients"
                :multiple="true"
                track-by="id"
                label="name"
                open-direction="bottom"
                :clear-on-select="false"
                :searchable="true"
                :custom-label="customLabel"
                :close-on-select="false"
                :taggable="true"
                placeholder="Vyberte príjemcu"
                :show-labels="false"
              />
              <div class="checkbox">
                <input
                  :id="'email-recipients'"
                  v-model="allRecipientsSelected"
                  type="checkbox"
                  :value="allRecipientsSelected"
                  name="all-recipients"
                  @input="selectAllRecipients"
                >
                <label :for="'email-recipients'">
                  Vybrať všetko
                </label>
              </div>
              <label v-if="form.selectedRecipients.error" class="error" for="select-recipients">Príjemca musí byť vyplnený.</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="time-limit"
                class="text-dark text-bold"
              >
                Nutné odpovedať do
              </label>
              <date-time-input
                v-model="validTo"
                name="time-limit"
                :value="validTo"
              />
            </div>
          </div>
          <div
            v-if="order.divided"
            class="col-md-4"
          >
            <div class="form-group">
              <label
                for="part"
                class="text-dark text-bold"
              >
                Časť zákazky <small>*</small>
              </label>
              <multiselect
                v-model="part"
                :options="order._parts"
                track-by="id"
                label="name"
                open-direction="bottom"
                :clear-on-select="false"
                :searchable="true"
                placeholder="Vyberte časť"
                :show-labels="false"
              />
              <label
                v-if="form.part.error"
                class="error"
                for="part"
              >
                Musíte vybrať časť zákazky.
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="text-dark text-bold">
                Správa
              </label>
              <editor
                ref="bodyEditor"
                v-model="body"
                :init="mceConfig"
              />
              <label
                v-if="form.body.error"
                class="error"
              >
                Počet znakov: {{ bodyLength }} z {{ bodyLengthLimit }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="text-dark text-bold">
              Súbor s prílohou
            </label>
            <file-upload-element
              v-model="files"
              :attachments="attachments"
              @remove-saved="removeSavedFile"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="row">
        <button
          class="btn btn-tenderia-grey-blue btn-inverse"
          @click="goBack"
        >
          <i class="icon-arrow-left" /> Späť
        </button>
        <button
          class="btn btn-warning"
          @click="setMessageButton('draft')"
        >
          <i class="icon-save" /> Uložiť ako koncept
        </button>
        <button
          class="btn btn-success"
          @click="setMessageButton('sent')"
        >
          <i class="icon-send" /> Odoslať správu
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimeInput from '@/components/forms/DateTimeInput.vue'
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
//import UploadManager from '@/components/utils/UploadManager.js'
import Multiselect from 'vue-multiselect'
import { isEqual } from 'lodash'
import { useOrderStore } from '@/storePinia/orderStore'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'AdminCommunicationAdd',
  components: { DateTimeInput, FileUploadElement, Multiselect, Editor },
  inject: ['UploadProgressModal', 'blockNonPremiumUser'],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      orderStore: useOrderStore(),
      bodyLengthLimit: 64000,
      files: [],
      subject: '',
      body: '',
      bodyLength: 0,
      type: '',
      part: '',
      recipients: [],
      selectedRecipients: [],
      allRecipientsSelected: false,
      validTo: '',
      signature: '',
      messageState: 'draft',
      attachments: [],
      form: {
        subject: {
          error: false,
          valid: false
        },
        type: {
          error: false,
          valid: false
        },
        selectedRecipients: {
          error: false,
          valid: false
        },
        part: {
          error: false,
          valid: false
        },
        body: {
          error: false,
          valid: false
        }
      },
      mceConfig: {
        height: 300,
        inline: false,
        theme: 'modern',
        language: 'sk',
        fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 39px 34px 38px 42px 48px',
        plugins: 'print colorpicker textcolor lists link table paste',
        toolbar1: 'formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat'
      },
      messageTypeOptions: [
        { code: 'zvpd', text: 'Žiadosť o vysvetlenie alebo doplnenie predložených dokladov' },
        { code: 'zvp', text: 'Žiadosť o vysvetlenie ponuky' },
        { code: 'zvnp', text: 'Žiadosť o vysvetlenie mimoriadne nízkej ponuky' },
        { code: 'zpde', text: 'Žiadosť o predloženie dokladov nahradených Jednotným európskym dokumentom' },
        { code: 'vsp', text: 'Vysvetlenie súťažných podkladov' },
        { code: 'znio', text: 'Žiadosť o nahradenie inej osoby' },
        { code: 'zns', text: 'Žiadosť o nahradenie subdodávateľa' },
        { code: 'iop', text: 'Informácia o otváraní ponúk' },
        { code: 'ovu', text: 'Oznámenie o vylúčení uchádzača' },
        { code: 'ovp', text: 'Oznámenie o vylúčení ponuky' },
        { code: 'zop', text: 'Zápisnica z otvárania ponúk' },
        { code: 'ovhp', text: 'Oznámenie o vyhodnotení ponúk' },
        { code: 'id', text: 'Iný dokument' }
      ]
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  },
  watch: {
    subject () {
      this.validateSubject()
    },
    type () {
      this.validateType()
    },
    selectedRecipients: {
      handler (val) {
        if (isEqual(val, this.recipients)) {
          this.allRecipientsSelected = true
        } else {
          this.allRecipientsSelected = false
        }
      },
      deep: true,
      immediate: false
    }
  },
  created () {
    this.signature = `<p></p>
    <p style="margin:0px; line-height:1.5;"><span style="font-size:14px;"><em><strong>${this.globals.user.firstname} ${this.globals.user.lastname}</strong></em></span></p>
    <p style="margin:0px; line-height:1.5;"><span style="font-size:14px;"><em><strong>${this.order.organization.name}</strong></em></span></p>
    <p style="margin:0px; line-height:1.5;"><span style="font-size:14px;"><em><strong>${this.order.organization.address} ${this.order.organization.address2}, ${this.order.organization.zip}, ${this.order.organization.city}</strong></em></span></p>
    `

    if (this.$route.params.action === 'reply') {
      this.subject = 'RE: ' + this.$route.params.message.subject
      this.recipients.push({
        name: this.$route.params.message.from.firstname + ' ' + this.$route.params.message.from.lastname,
        email: this.$route.params.message.from.email,
        id: this.$route.params.message.from.id,
        orgId: this.$route.params.message.organization.id
      })
      this.selectedRecipients = this.recipients
    } else if (this.$route.params.action === 'draft') {
      const { message } = this.$route.params
      this.subject = message.subject
      this.selectedRecipients = this.$route.params.message.recipients.map((el, index) => ({
        name: this.$route.params.message.recipientsOrgs[index].name || el.firstname + ' ' + el.lastname,
        email: el.email,
        id: el.id,
        orgId: this.$route.params.message.recipientsOrgs[index].id
      }))
      this.body = message.body
      this.validTo = message.validTo
      this.type = this.messageTypeOptions.find(opt => opt.code === message.type)
      this.part = message.part
      this.attachments = message.attachments
      this.buildRecipientsSelect()
    } else {
      this.buildRecipientsSelect()
    }

    this.body = this.signature
  },
  mounted () {
    this.$watch('body', () => {
      this.bodyLength = this.$refs.bodyEditor.editor.getContent({ format: 'text' }).length
      this.validateBody()
      if (this.form.body.error) {
        this.$refs.bodyEditor.editor.windowManager.alert('Presiahli ste limit 64 000 znakov v správe. Ak potrebujete odoslať takúto správu nahrajte text správy ako prílohu.')
      }
    })
  },
  methods: {
    selectAllRecipients () {
      this.allRecipientsSelected = !this.allRecipientsSelected
      if (this.allRecipientsSelected) {
        this.selectedRecipients = this.recipients.map((item) => item)
      } else {
        this.selectedRecipients = []
      }
    },
    validateRecipients () {
      var status = this.form.selectedRecipients
      status.valid = status.error = false
      if (this.selectedRecipients.length > 0) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateSubject () {
      var status = this.form.subject
      status.valid = status.error = false
      var length = this.subject.length
      if (length > 1 && length < 251) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateType () {
      var status = this.form.type
      status.valid = status.error = false
      if (this.type && this.type.code.length > 0) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validatePart () {
      var status = this.form.part
      status.valid = status.error = false
      if (this.part && this.part.id) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateBody () {
      var status = this.form.body
      status.valid = status.error = false
      if (this.bodyLength > this.bodyLengthLimit) {
        status.error = true
      } else {
        status.valid = true
      }
    },
    validate () {
      this.validateSubject()
      this.validateRecipients()
      this.validateType()
      this.validateBody()

      let valid = this.form.subject.valid && this.form.type.valid && this.form.selectedRecipients.valid && this.form.body.valid

      if (this.order.divided) {
        this.validatePart()
        valid = valid && this.form.part.valid
      }

      return valid
    },
    goBack () {
      window.history.go(-1)
    },
    onError (error) {
      this.CloseLoading()
      this.ErrorAlert({ text: 'Pri odosielaní správy nastala chyba' }, error)
    },
    async setMessageButton (state)
    {
      if (state === 'draft')
      {
        if (await this.blockNonPremiumUser('Možnosť ukladania rozpracovaných správ je dostupná len s balíkom Prémiových služieb') === true)  return
      }
      if (!this.validate())
      {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
      else
      {
        this.messageState = state
        await this.sendMessage()
      }
    },
    async sendMessage () {
      if (this.files.length > 0) {
        const titles = ['Ukladám prílohy správy', 'Prosím čakajte', 'Zvážte aktiváciu Prémiových služieb', 'Získate zobrazenie priebehu nahrávania', 'Získate plnú rýchlosť nahrávania súborov', 'Získate mnoho ďalších funkcií']
        await this.UploadProgressModal({ files: this.files, title: 'Ukladám prílohy správy', titles, onDone: this.createMessage, onError: this.onError, fields: { order: this.order.id } })
        //this.UploadProgressAlert({ title: 'Ukladám prílohy správy' }, this.statsStore)
        //const uploader = new UploadManager(this.createMessage, this.onError, { order: this.order.id }, this.$http)
        //uploader.startUpload(this.files, null)
      } else {
        await this.createMessage([])
      }
    },
    async createMessage (attachments) {
      this.ShowLoading({ title: this.messageState === 'sent' ? 'Odosielam správu' : 'Ukladám správu' })
      var data = {
        message: {
          type: this.type.code,
          subject: this.subject,
          body: this.body,
          order: this.part ? this.part.id : this.order.id,
          organization: this.order.organization.id,
          bcc: this.selectedRecipients.map(recipient => {
            return recipient.id
          }),
          bccOrgs: this.selectedRecipients.map(recipient => {
            return recipient.orgId
          }),
          validTo: this.validTo,
          access: 'order',
          attachments: [...attachments, ...this.attachments.map(attachment => attachment.id)]
        }
      }
      if (this.$route.params.action === 'reply') {
        data.message.parent = this.$route.params.message.id
      }
      if (this.$route.params.action === 'draft') {
        data.message.id = this.$route.params.message.id
      }
      try {
        const response = await this.$http.post('/message/set', data)
        if (this.messageState === 'sent') {
          await this.$http.post('/message/send', { message: { id: response.data.id } })
        }
        this.CloseLoading()
        this.OkAlert({ text: this.messageState === 'sent' ? 'Správa bola odoslaná' : 'Správa bola uložená' })
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-communication`, params: { orderId: this.order.id } })
      } catch (error) {
        console.log(error)
        this.onError(error)
      }
    },
    customLabel ({ name, email }) {
      return `${name} - (${email})`
    },
    buildRecipientsSelect () {
      this.recipients = this.order.interested.map((item) => ({
        name: item.organization.name,
        email: item.user.email,
        id: item.user.id,
        orgId: item.organization.id
      }))
    },
    removeSavedFile (value) {
      try {
        this.attachments.splice(value.index, 1)
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
      }
    }
  }
}
</script>
