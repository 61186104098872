<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title pull-left">
          Identifikácia dodávateľa
        </h4>
        <div class="actions">
          <button
            class="btn btn-tenderia-blue"
            @click.prevent="fillApplicantDetails()"
          >
            Prebrať údaje z účtu
          </button>
        </div>
      </div>
      <div class="card-block">
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label
                class="control-label"
                for="businessName"
              >
                <span class="text-dark text-bold">
                  Obchodné meno alebo názov uchádzača <small>*</small>
                </span><br />úplné oficiálne obchodné meno alebo názov uchádzača
              </label>
              <div>
                <input
                  id="businessName"
                  type="text"
                  v-model="offer.applicantDetails.businessName"
                  :class="{ error: form.businessName.error, valid: form.businessName.valid }"
                  class="form-control"
                  required="required"
                  aria-required="true"
                >
                <label
                  v-if="form.businessName.error"
                  class="error"
                  for="businessName"
                >
                  Obchodné meno musí byť vyplnené.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="address"
              >
                <span class="text-dark text-bold">
                  Sídlo alebo miesto podnikania uchádzača <small>*</small>
                </span><br />úplná adresa sídla alebo miesta podnikania uchádzača
              </label>
              <div>
                <textarea
                  id="address"
                  v-model="offer.applicantDetails.address"
                  :class="{ error: form.address.error, valid: form.address.valid }"
                  class="form-control"
                ></textarea>
                Počet zostávajúcich znakov: {{ addressTextRemaining }}<br />
                <label
                  v-if="form.address.error"
                  class="error"
                  for="address"
                >
                  Miesto podnikania uchádzača musí byť vyplnené.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">
                <span class="text-dark text-bold">
                  Platca DPH <small>*</small>
                </span>
              </label>
              <div>
                <div class="radio radio-inline">
                  <input
                    id="vat-yes"
                    type="radio"
                    v-model="offer.applicantDetails.vatPayer"
                    value="yes"
                  /><label for="vat-yes">
                    Áno
                  </label>
                </div>
                <div class="radio radio-inline">
                  <input
                    id="vat-no"
                    type="radio"
                    v-model="offer.applicantDetails.vatPayer"
                    value="no"
                  /><label for="vat-no">
                    Nie
                  </label>
                </div>
                <label
                  v-if="form.vatPayer.error"
                  class="error"
                >
                  Musíte zadať či ste platcom DPH.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="ico"
              >
                <span class="text-dark text-bold">
                  IČO <small>*</small>
                </span>
              </label>
              <div>
                <input
                  type="text"
                  v-model="offer.applicantDetails.ico"
                  :class="{ error: form.ico.error, valid: form.ico.valid }"
                  class="form-control"
                  required="required"
                  aria-required="true"
                >
                <label
                  v-if="form.ico.error"
                  class="error"
                >
                  Musíte vyplniť platné IČO.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="country"
              >
                <span class="text-dark text-bold">
                  Štát <small>*</small>
                </span><br />názov štátu, podľa právneho poriadku ktorého bol uchádzač založený
              </label>
              <div>
                <country-select
                  v-model="offer.applicantDetails.country"
                  :value="offer.applicantDetails.country"
                ></country-select>
                <label
                  v-if="form.country.error"
                  class="error"
                  for="country"
                >
                  Štát musí byť vyplnený.
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div>
                <span class="text-dark text-bold">
                  Kontaktné údaje uchádzača
                </span><br />pre potreby komunikácie s uchádzačom počas verejného obstarávania
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="contactPerson"
              >
                <span class="text-dark text-bold">
                  Meno a priezvisko kontaktnej osoby <small>*</small>
                </span><br />
              </label>
              <div>
                <input
                  id="contactPerson"
                  type="text"
                  v-model="offer.applicantDetails.contactPerson"
                  :class="{ error: form.contactPerson.error, valid: form.contactPerson.valid }"
                  class="form-control"
                  required="required"
                  aria-required="true"
                >
                <label
                  v-if="form.contactPerson.error"
                  class="error"
                  for="contactPerson"
                >
                  Meno a priezvisko kontaktnej osoby musia byť vyplnené.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="phone"
              >
                <span class="text-dark text-bold">
                  Telefón <small>*</small>
                </span>
              </label>
              <div>
                <input
                  id="phone"
                  type="text"
                  v-model="offer.applicantDetails.phone"
                  :class="{ error: form.phone.error, valid: form.phone.valid }"
                  class="form-control"
                  required="required"
                  aria-required="true"
                >
                <label
                  v-if="form.phone.error"
                  class="error"
                  for="phone"
                >
                  Telefónne čislo musí byť uvedené v tvare  napr. +421123456789.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="fax"
              >
                <span class="text-dark text-bold">
                  Fax
                </span>
              </label>
              <div>
                <input
                  id="fax"
                  type="text"
                  v-model="offer.applicantDetails.fax"
                  :class="{ error: form.fax.error, valid: form.fax.valid }"
                  class="form-control"
                  required="required"
                  aria-required="true"
                >
                <label
                  v-if="form.fax.error"
                  class="error"
                  for="fax"
                >
                  Telefónne čislo pre fax ak ste ho uviedli musí byť v tvare  napr. +421123456789.
                </label>
              </div>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="email"
              >
                <span class="text-dark text-bold">
                  Email <small>*</small>
                </span>
              </label>
              <div>
                <input
                  id="email"
                  type="text"
                  v-model="offer.applicantDetails.email"
                  :class="{ error: form.email.error, valid: form.email.valid }"
                  class="form-control"
                  required="required"
                  aria-required="true"
                >
                <label
                  v-if="form.email.error"
                  class="error"
                  for="email"
                >
                  Email musí byť uvedený v tvare  napr. meno@domena.sk.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Doklady vyžadované výzvou
        </h4>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            {{ order.participationConditions }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label
              for="file"
              class="text-bold text-dark"
            >
              Prílohy
            </label>
          </div>
        </div>
        <div class="row hide">
          <div class="col-md-12">
            <input
              type="file"
              id="file"
              @change="setFiles"
              :multiple="statsStore.premiumActive"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span style="display: none">
              <input
                type="file"
                id="file"
                @change="setFiles"
                :multiple="statsStore.premiumActive"
              >
            </span>
            <div
              id="dropTarget"
              class="drop-area"
            >
              <i class="icon-upload-cloud drag-hide"></i>
              <span class="drag-visible">
                Súbory vložte sem
              </span>
              <span class="drag-hide">
                Pretiahnite sem dokumenty z plochy alebo priečinkov, ktoré chcete nahrať alebo
                <label for="file">
                  kliknite sem a vyberte súbory
                </label> na nahratie.
                <span>(Maximálna veľkosť jedného súboru je 2GB.)</span>
              </span>
            </div>
            <div v-if="messages.length > 0">
              <ul>
                <li
                  v-for="(message, index) in messages"
                  class="text-danger"
                  :key="`warning-message-${index}`"
                >
                  {{ message }}
                </li>
              </ul>
            </div>
            <div v-if="!statsStore.premiumActive" class="alert tw-bg-red-100" role="alert">
              <div class="d-flex">
                <div class="px-2">
                  <div class="tw-text-red-900 tw-font-semibold">UPOZORNENIE</div>
                  <div class="tw-text-red-700">Bez prémiových služieb môžete nahrávať súbory len jednotlivo. Celkový počet súborov však nie je obmedzený. Pre možnosť hromadného výberu súborov pre nahrávanie si aktivujte <a href="#" role="button" @click.prevent="premiumServices">Prémové služby.</a></div>
                </div>
              </div>
            </div>
            <table class="table">
              <tr>
                <th>Názov súboru</th>
                <th>Veľkosť</th>
                <th>Typ</th>
                <th></th>
              </tr>
              <tr
                v-for="(file, index) in offer.attachments"
                :key="file.id"
              >
                <td>{{ file.name }}</td>
                <td>{{ $format.formatFileSize(file.size) }}</td>
                <td>{{ $format.formatFileType(file.type) }}</td>
                <td>
                  <a
                    href=""
                    @click.prevent="removeSavedFileModal(index, offer.attachments)"
                  >
                    <i class="icon-delete"></i>
                  </a>
                </td>
              </tr>
              <tr
                v-for="(file, index) in files"
                :key="index + file.name"
              >
                <td>{{ file.name }}</td>
                <td>{{ $format.formatFileSize(file.size) }}</td>
                <td>{{ $format.formatFileType(file.type) }}</td>
                <td>
                  <a
                    href=""
                    @click.prevent="removeFile(index)"
                  >
                    <i class="icon-delete"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      id="add-offer"
    >
      <div class="card-heading">
        <h4 class="card-title">
          Ponuka
        </h4>
      </div>
      <div class="card-block">
        <p class="text-bold text-dark text-center pdd-vertical-25">
          Uchádzač má venovať vyplneniu návrhu na plnenie kritérií dostatok pozornosti a vážnosti, tak, aby predložil ponuku, v ktorej nedôjde k predloženiu rôznych návrhov na plnenie toho istého kritéria. V prípade, ak sa v ponuke uchádzača vyskytnú rôzne návrhy na plnenie toho istého kritéria na vyhodnotenie ponúk, bude postupovať verejný obstarávateľ podľa Výkladového stanoviska č. 1/2021 k vyhodnoteniu ponuky obsahujúcej viac návrhov na plnenie toho istého kritéria.
        </p>
        <div
          class="row"
          v-if="order.items.length > 0"
        >
          <div class="col-md-12">
            <h3>Položky</h3>
            <items-table
              v-if="ready"
              :order="order"
              :offer="offer"
              :decimal-count="decimalCount"
              :editable="true"
              @offer-items="validateItems"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3>Kritériá</h3>
          </div>
        </div>
        <div
          class="row form-group"
          v-for="criterion in criteria"
          :key="criterion._id"
        >
          <div class="col-md-8">
            <label
              :for="criterion._id"
              class="text-bold text-dark"
            >
              {{ criterion.name}} {{ taxInclusion(criterion) }}
            </label><br />
            <span style="font-size: 80%">
              Minimálna hodnota: {{ criterion.minimalValue === Number.MIN_SAFE_INTEGER ? "-&infin;" : formatNumber(criterion.minimalValue, criterion.decimalCount) }} Maximálna hodnota: {{ criterion.maximalValue === Number.MAX_SAFE_INTEGER ? "&infin;" : formatNumber(criterion.maximalValue, criterion.decimalCount) }} Počet desatinných miest: {{ criterion.decimalCount }}
            </span><br />
            <label
              v-if="criterion.error"
              class="error"
              for="criterion._id"
            >
              Hodnota kritéria musí byť číslo a musí spadať do rozsahu určeného pre toto kritérium.
            </label>
          </div>
          <div class="col-md-2">
            <input
              :id="criterion._id"
              type="text"
              v-model="criterion.userInput"
              @change="validateCriterion(criterion)"
              :class="{ error: criterion.error, valid: criterion.valid }"
              class="form-control"
            />
          </div>
          <div class="col-md-2 text-bold text-dark">
            {{ criterion.unit }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3>Prílohy</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span style="display: none">
              <input
                type="file"
                id="file2"
                @change="setFiles2"
                :multiple="statsStore.premiumActive"
              >
            </span>
            <div
              id="dropTarget2"
              class="drop-area"
            >
              <i class="icon-upload-cloud drag-hide"></i>
              <span class="drag-visible">
                Súbory vložte sem
              </span>
              <span class="drag-hide">
                Pretiahnite sem dokumenty z plochy alebo priečinkov, ktoré chcete nahrať alebo
                <label for="file2">
                  kliknite sem a vyberte súbory
                </label> na nahratie.
                <span>(Maximálna veľkosť jedného súboru je 2GB.)</span>
              </span>
            </div>
            <div v-if="messages2.length > 0">
              <ul>
                <li
                  v-for="(message, index) in messages2"
                  class="text-danger"
                  :key="`warning-message-${index}`"
                >
                  {{ message }}
                </li>
              </ul>
            </div>
            <div v-if="!statsStore.premiumActive" class="alert tw-bg-red-100" role="alert">
              <div class="d-flex">
                <div class="px-2">
                  <div class="tw-text-red-900 tw-font-semibold">UPOZORNENIE</div>
                  <div class="tw-text-red-700">Bez prémiových služieb môžete nahrávať súbory len jednotlivo. Celkový počet súborov však nie je obmedzený. Pre možnosť hromadného výberu súborov pre nahrávanie si aktivujte <a href="#" role="button" @click.prevent="premiumServices">Prémové služby.</a></div>
                </div>
              </div>
            </div>
            <table class="table">
              <tr>
                <th>Názov súboru</th>
                <th>Veľkosť</th>
                <th>Typ</th>
                <th></th>
              </tr>
              <tr
                v-for="(file, index) in offer.attachments2"
                :key="file.id"
              >
                <td>{{ file.name }}</td>
                <td>{{ $format.formatFileSize(file.size) }}</td>
                <td>{{ $format.formatFileType(file.type) }}</td>
                <td>
                  <a
                    href=""
                    @click.prevent="removeSavedFileModal(index, offer.attachments2)"
                  >
                    <i class="icon-delete font-size-25 text-danger"></i>
                  </a>
                </td>
              </tr>
              <tr
                v-for="(file, index) in files"
                :key="index + file.name"
              >
                <td>{{ file.name }}</td>
                <td>{{ $format.formatFileSize(file.size) }}</td>
                <td>{{ $format.formatFileType(file.type) }}</td>
                <td>
                  <a
                    href=""
                    @click.prevent="removeFile2(index)"
                  >
                    <i class="icon-delete font-size-25 text-danger"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click="goBack"
      >
        <i class="icon-arrow-left"></i> Späť
      </button>
      <button
        class="btn btn-success"
        @click="saveOffer(sendOffer)"
        :disabled="!canSendOffer"
      >
        <i class="icon-send"></i> Uložiť a odoslať ponuku
      </button>
    </div>
  </div>
</template>

<script>

import { useStatsStore } from '@/storePinia/statsStore'
import validator from '@/components/validator.js'
import ItemsTable from '@/components/order/common/components/ItemsTable.vue'
import BaseOfferForm from '@/components/order/common/components/BaseUserOfferForm.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

var dragDrop = require('drag-drop')

export default {
  name: 'MarketResearchPublicOfferForm',
  extends: BaseOfferForm,
  components: { ItemsTable },
  inject: ['showPurchaseModal'],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      form: {
        ico: {
          error: false,
          valid: false
        }
      }
    }
  },
  watch: {
    'offer.applicantDetails.ico': function () {
      this.validateIco()
    }
  },
  methods: {
    premiumServices()
    {
      this.showPurchaseModal({ title: 'Prémiové služby' })
    },
    validateOffer () {
      this.validateIco()
      this.validateBusinessName()
      this.validateAddress()
      this.validateCountry()
      this.validateContactPerson()
      this.validatePhone()
      this.validateFax()
      this.validateEmail()
      this.validateVatPayer()

      return this.validateCriteria() && this.form.businessName.valid && this.form.ico.valid && this.form.address.valid && this.form.country.valid && this.form.contactPerson.valid && this.form.phone.valid && this.form.email.valid && this.form.vatPayer.valid
    },
    validateIco () {
      var status = this.form.ico
      status.error = status.valid = false
      if (validator.validateIco(this.offer.applicantDetails.ico)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateBusinessName () {
      var status = this.form.businessName
      status.error = status.valid = false
      var length = this.offer.applicantDetails.businessName.length
      if (length > 0 && length <= 256) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    fillApplicantDetails () {
      var user = this.globals.user
      var userOrganization = this.globals.userOrganization
      this.offer.applicantDetails.email = user.email
      this.offer.applicantDetails.phone = user.phone
      this.offer.applicantDetails.contactPerson = user.title1 + ' ' + user.firstname + ' ' + user.lastname + ' ' + user.title2
      this.offer.applicantDetails.vatPayer = userOrganization.icdph ? 'yes' : 'no'
      this.offer.applicantDetails.ico = userOrganization.ico
      this.offer.applicantDetails.businessName = userOrganization.name
      this.offer.applicantDetails.country = userOrganization.country ? userOrganization.country.toUpperCase() : ''
      this.offer.applicantDetails.address = userOrganization.address + ' ' + userOrganization.address2 + '\n' + userOrganization.city + '\n' + userOrganization.zip
    },
    validateItems (data) {
      this.items = data.items
      if (this.priceCriterion) {
        this.priceCriterion.userInput = this.formatNumber(data.total, this.decimalCount)
        this.validateCriterion(this.priceCriterion)
      }
    }
  },
  async mounted () {
    let vm = this

    if (document.querySelector('#dropTarget')) {
      dragDrop('#dropTarget', function (files) {
        for (let file of files) {
          vm.files.push(file)
        }
      })
    }
    if (document.querySelector('#dropTarget2')) {
      dragDrop('#dropTarget2', function (files) {
        for (let file of files) {
          vm.files2.push(file)
        }
      })
    }

    this.prepareCriteria()

    if (this.order.biddingMethod === 'lowestPrice') {
      let criterion = this.findPriceCriterion()
      this.decimalCount = criterion.decimalCount
    }

    try {
      let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })

      if (offer.id) {
        this.offer = offer
        this.offer.applicantDetails.ico = offer.applicantDetails.ico[0]
        this.items = offer.items
        this.setProposals()
        this.ready = true
        if (this.$route.params.action === 'send') {
          this.sendOffer()
        }
      } else {
        this.fillApplicantDetails()
      }

    } catch (error) {
      console.log(error)
    }
  },
}
</script>
